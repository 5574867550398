import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
let router = new Router({
    mode: "history",
    routes: [

        {
            path: "/",
            name: "home",
            props: true,
            component: () =>
                import ("./views/Home/indexx"),

        },


        //payment routes
        {
            path: '/payment',
            props: true,
            name: 'payment',
            component: () =>
                import ('./views/Payment/payment'),
        },
        {
            path: '/success',
            props: true,
            name: 'success',
            component: () =>
                import ('./views/Payment/success'),
        },
        {
            path: '/failure',
            props: true,
            name: 'failure',
            component: () =>
                import ('./views/Payment/failure'),
        },

        // end of payment routes




        {
            path: "/servererror",
            name: "servererror",
            props: true,
            component: () =>
                import ("./layout/500"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "*",
            name: "404pagenotfound",
            props: true,
            component: () =>
                import ("./layout/404"),
            meta: {
                requiresAuth: false,
            },
        },
    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
});

export default router;