import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import axios from "axios";
import ServerError from "./layout/500";
import PageLoader from "./components/PageLoader";
import ImageLoader from "./components/ImageLoader";
import VueElementLoading from "vue-element-loading";
// import VueGtag from "vue-gtag";

// Vue.use(VueGtag, {
//     config: { id: "G-27L9FDWRC9" }
// });

import OwlCarousel from "vue-owl-carousel";
Vue.component("OwlCarousel", OwlCarousel);

import 'viewerjs/dist/viewer.css'
import viewer from 'v-viewer'
Vue.use(viewer)
Vue.component("VueElementLoading", VueElementLoading);
Vue.component("ServerError", ServerError);
Vue.component("PageLoader", PageLoader);
Vue.component("ImageLoader", ImageLoader);

import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);



Vue.config.productionTip = false;


// axios.defaults.baseURL = 'http://192.168.53.5:5000/';
// Vue.prototype.baseURL = "http://192.168.53.5:5000/"

axios.defaults.baseURL = "https://wtiadmin.in/";
Vue.prototype.baseURL = "https://wtiadmin.in/";
Vue.prototype.mediaURL = "https://wtiadmin.in/wp/";
Vue.mixin({
    data() {
        return {
            webpSupported: true,
        };
    },

    created() {
        (async() => {
            // If browser doesn't have createImageBitmap, we can't use webp.
            if (!self.createImageBitmap) {
                this.webpSupported = false;
                return;
            }
            const webpData =
                "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";
            // Retrieve the Image in Blob Format
            const blob = await fetch(webpData).then((r) => r.blob());
            // If the createImageBitmap method succeeds, return true, otherwise false
            this.webpSupported = await createImageBitmap(blob).then(
                () => true,
                () => false
            );
            if (this.webpSupported) {
                Vue.prototype.mediaURL = "https://wtiadmin.in/wp/";
            } else {
                Vue.prototype.mediaURL = "https://wtiadmin.in/up/";
            }
        })();
    },
});

new Vue({
    vuetify,
    router,
    render: (h) => h(App),
}).$mount("#app");